<template>
  <div>
    <b-card
      title="ระบบหลังบ้าน"
      no-body
    >
      <b-card-header>
        <b-card-title>ระบบหลังบ้าน</b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0">
          .
        </b-card-text>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Token ไลนแจ้งเตือน OTP เข้าระบบ"
              label-for="mc-last-name"
            >
              <b-input-group>
                <b-form-input
                  v-model="systemSettingForm.line_noti_otp"
                  placeholder="Token ไลนแจ้งเตือน OTP เข้าระบบ"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="mt-2">
          <b-col md="6">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              @click="saveSettings()"
            >
              บันทึก
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      title="สมาชิก"
      no-body
    >
      <b-card-header>
        <b-card-title>ระบบสมาชิก</b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0">
          .
        </b-card-text>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Token ไลนแจ้งเตือนลูกค้าสมัครใหม่"
              label-for="mc-last-name"
            >
              <b-input-group>
                <b-form-input
                  v-model="systemSettingForm.line_noti_register"
                  placeholder="Token ไลนแจ้งเตือนลูกค้าสมัครใหม่"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Token ไลนแจ้งเตือนแก้ไขข้อมูลลูกค้า"
              label-for="mc-last-name"
            >
              <b-input-group>
                <b-form-input
                  v-model="systemSettingForm.line_noti_editmember"
                  placeholder="Token ไลนแจ้งเตือนแก้ไขข้อมูลลูกค้า"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="mt-2">
          <b-col md="6">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              @click="saveSettings()"
            >
              บันทึก
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      title="ระบบออโต้"
      no-body
    >
      <b-card-header>
        <b-card-title>ระบบออโต้</b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0">
          .
        </b-card-text>
      </b-card-header>
      <b-card-body>
        <b-row class="mb-10">
          <b-col md="12">
            <div><b>ระบบฝากเงิน</b></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="ยอดฝากขั้นต่ำ"
              label-for="mc-last-name"
            >
              <b-input-group
                append="฿"
              >
                <b-form-input
                  v-model="systemSettingForm.min_deposit"
                  placeholder="ยอดฝากขั้นต่ำ ฿"
                  @keypress="isNumber($event)"
                  @paste.prevent
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="บังคับเล่นตามจำนวนยอดฝาก"
              label-for="mc-last-name"
            >
              <b-input-group append="เท่า">
                <b-input-group-prepend is-text>
                  <input
                    type="checkbox"
                    :checked="systemSettingForm.enforced_deposit_turn"
                  >
                </b-input-group-prepend>
                <b-form-input
                  v-model="systemSettingForm.enforced_deposit_turn"
                  placeholder="บังตับทำเทิร์น X "
                  @keypress="isNumber($event)"
                  @paste.prevent
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="ไลนแจ้งเตือน SMS (Token)"
              label-for="mc-last-name"
            >
              <b-input-group>
                <b-form-input
                  v-model="systemSettingForm.line_noti_deposit"
                  placeholder="คีย์ไลนแจ้งเตือน SMS"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="รหัสรับ SMS (Secret)"
              label-for="mc-last-name"
            >
              <b-input-group>
                <b-form-input
                  v-model="systemSettingForm.deposit_sms_key"
                  placeholder="รหัสรับ SMS (Secret)"
                />
              </b-input-group>
              <br>SMS Forward URL: {{ `https://${smsForwardBaseURL}/api/botauto/v2/sms/${systemSettingForm.deposit_sms_key || '[sms-secret]'}` }}
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-10">
          <b-col md="12">
            <div><hr><b>ระบบถอนเงิน</b></div>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="ยอดถอนขั้นต่ำ"
              label-for="mc-last-name"
            >
              <b-input-group
                append="฿"
              >
                <b-form-input
                  v-model="systemSettingForm.min_withdraw"
                  placeholder="ยอดถอนขั้นต่ำ ฿"
                  @keypress="isNumber($event)"
                  @paste.prevent
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="ยอดถอนด้วยบอทสูงสุด"
              label-for="mc-last-name"
            >
              <b-input-group
                append="฿"
              >
                <b-form-input
                  v-model="systemSettingForm.max_auto_withdraw"
                  placeholder="ยอดถอนด้วยบอทสูงสุด ฿"
                  @keypress="isNumber($event)"
                  @paste.prevent
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="อนุมัติออโต้"
              label-for="mc-last-name"
            >
              <b-form-checkbox
                v-model="systemSettingForm.auto_approve_withdraw"
                value="Y"
                unchecked-value="N"
              >
                อนุมัติออโต้
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="อนุมัติออโต้ยอดสูงสุด"
              label-for="mc-last-name"
            >
              <b-input-group
                append="฿"
              >
                <b-form-input
                  v-model="systemSettingForm.auto_approve_withdraw_max"
                  placeholder="อนุมัติออโต้ยอดสูงสุด ฿"
                  @keypress="isNumber($event)"
                  @paste.prevent
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Token ไลนแจ้งเตือนถอนเงิน"
              label-for="mc-last-name"
            >
              <b-input-group>
                <b-form-input
                  v-model="systemSettingForm.line_noti_withdraw"
                  placeholder="Token ไลนแจ้งเตือนถอนเงิน"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="mt-2">
          <b-col md="6">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="primary"
              @click="saveSettings()"
            >
              บันทึก
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BBadge, BFormSelect, BPagination, BButton, BCardBody, BRow, BCol, BForm, BCardHeader, BCardTitle, BFormGroup, BFormInput, BFormCheckbox, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
// import flatPickr from 'vue-flatpickr-component'
// import Cleave from 'vue-cleave-component'
// import moment from 'moment'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BBadge,
    BFormSelect,
    BPagination,
    BFormInput,
    BButton,
    BCardBody,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      smsForwardBaseURL: location.host,
      systemSettingInfo: {},
      systemSettingForm: {},
      allowKeys: ['min_deposit', 'min_withdraw', 'max_auto_withdraw', 'auto_approve_withdraw_max', 'auto_approve_withdraw', 'line_noti_withdraw', 'auto_approve_withdraw_queue', 'line_noti_deposit', 'deposit_sms_key', 'enable_withdraw', 'frontend_withdraw_message', 'line_noti_otp', 'line_noti_editmember', 'line_noti_register', 'enforced_deposit_turn'],
      bankListOptions: JSON.parse(localStorage.getItem('bank-lists') || '[]'),
    }
  },
  computed: {
  },
  mounted() {
    // Set the initial number of items
    this.fetchSettings()
  },
  methods: {
    async fetchSettings() {
      try {
        const { data: { settings } } = await this.$http.get('system-settings')
        console.log('settings', settings)
        this.systemSettingInfo = {}
        this.systemSettingForm = {}
        this.allowKeys.forEach(settingKey => {
          const settingRecord = settings.filter(st => st.key === settingKey)
          if (settingRecord.length === 0)
            return
          this.systemSettingInfo[settingKey] = settingRecord[0].value
          this.systemSettingForm[settingKey] = settingRecord[0].value
        })
      } catch (err) {
        // ...
      }
    },
    async saveSettings() {
      const changed = {}
      this.allowKeys.forEach(settingKey => {
        if (this.systemSettingInfo[settingKey] !== this.systemSettingForm[settingKey])
          changed[settingKey] = this.systemSettingForm[settingKey]
      })

      if (Object.keys(changed).length === 0)
        return

      const { data: changeResults } = await this.$http.put('system-settings', { settings: changed })
      console.log(changeResults)
      if (changeResults.status) {
        this.$bvToast.toast('แก้ไขการตั้งค่าระบบ', {
          variant: 'success',
          title: 'แก้ไขการตั้งค่าระบบสำเร็จ',
          toaster: 'b-toaster-bottom-right',
          solid: true,
          appendToast: false,
        })
      }
    },
    isNumber(evt) {
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
        return false
      }
      return true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.mb-10 {
  margin-bottom: 10px;
}
</style>
